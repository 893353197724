<template>
    <template v-if="showAccordion">
        <ORowContainer style="max-height: 58px;" class="border-bottom">
            <div class="accordion" id="navigateAcordeonTop">
                <div class="accordion-item border-0">
                    <h2 class="accordion-header" id="navigateAcordeonHeader">
                        <button class="accordion-button collapsed py-2 " type="button" ref="navigateButton" @click="expandNavigate" data-bs-toggle="collapse" data-bs-target="#navigateAcordeon" aria-expanded="false" aria-controls="navigateAcordeon">
                            <div>
                                <div>
                                    <b class="me-2 ">{{$t('Navigation tree')}} </b>
                                </div>
                                <div>
                                    <span style="font-size: small;">Selected {{ dataObject.current.NodeType }}: {{ dataObject.current.Name }}</span>
                                </div>
                            </div>
                        </button>
                    </h2>
                    <div id="navigateAcordeon" class="accordion-collapse collapse position-absolute w-100 me-3" style="z-index: 1000;" aria-labelledby="navigateAcordeonHeader">
                        <div class="accordion-body p-0">
                            <slot/>
                        </div>
                    </div>
                </div>
            </div>
        </ORowContainer>
    </template>
    <template v-else>
    <ORowContainer class="h-100">
        <ORowContainer class="accordion-header p-2" style="background-color: #dae6f0; max-height: 58px;">
                    <h2 class="accordion-header" id="navigateAcordeonHeader">
                        <button class="accordion-button py-2" style="pointer-events: none;">
                            <div>
                                <div>
                                    <b class="me-2 ">{{$t('Navigation tree')}} </b>
                                </div>
                                <div>
                                    <span style="font-size: small;">Selected {{ dataObject.current.NodeType }}: {{ dataObject.current.Name }}</span>
                                </div>
                            </div>
                        </button>
                    </h2>
        </ORowContainer>
            <slot/>
    </ORowContainer>
    </template>
</template>
<script setup>

const props = defineProps({
    dataObject: {
        type: Object,
    },
    showAccordion: {
        type: Boolean,
        default: false
    }
})
</script>

<style scoped>

.accordion-button:focus {
    outline: none !important;
    box-shadow: none !important;
}
</style>